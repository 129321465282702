

import { useSelector } from 'react-redux';

import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Permission = ({ children, permissions } ) => {

  const navigate = useNavigate();
  const selectUsername = (state) => state.userAction?.user?.username || '';
  const username = useSelector(selectUsername);

  const selectMenu = (state) => state.userAction?.menu || [];
  const menu = useSelector(selectMenu);
  //const [hasAdminPermission, sethasAdminPermission] = useState(null);

  function hasDashboardItem(targetmenu) {

    for (const item of targetmenu) {

      if (item.key === permissions) {
        return true;
      }
      
      if (item.childrenmenu && item.childrenmenu.length > 0) {
        // 如果当前元素有子菜单，则递归检查子菜单
        if (hasDashboardItem(item.childrenmenu)) {
          return true;
        }
      }
    }
  
    return false; // 没有找到 dashboard 键的项时返回 false
  }

  const useHasAdminPermission = () => {
    //此处要作改动判断state的menu里有没有这个菜单
    //return username === 'fang';
    let result
    if(menu.length === 0){
          result= true;
    }else{
      result=  hasDashboardItem(menu);
    }
    return result
         
  };
  const hasAdminPermission = useHasAdminPermission();

  useEffect(() => {
    // 根据checkPermission函数的结果决定是否跳转

    if (!hasAdminPermission) {
      navigate('/', { replace: true });
    }
  });

  return hasAdminPermission ? <>{children}</> : null;
};

export default Permission;