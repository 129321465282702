import numAction from "./index"

let reducer = (state= {...numAction.state,},action)=>{

  let newState = { ...state };
    for(let key in numAction.actionnames){
        if(action.type=== numAction.actionnames[key]){
            numAction.actions[numAction.actionnames[key]](newState,action)
        }
        break;
    }
    
return newState
}
export default reducer