
import React from 'react';
import { Navigate, useLocation,useNavigate } from "react-router-dom";


const whiteList = ['/login']; // 白名单不需要登录
const Logged  = () => {
    // 假设你存储了access_token在localStorage中
    const accessToken = localStorage.getItem('access_token');
    return Boolean(accessToken); // 如果存在access_token，则返回true表示已登录
  };
const Inwhitelist = (path) => {
    return whiteList.includes(path);
  };

function  Guard(props) {

  const navigate=useNavigate()
  const location = useLocation();
  const children = props.children || null;
  const isLogged  = Logged()
  const isInwhitelist  = Inwhitelist(location.pathname)


  


  if(isLogged){
    if(location.pathname ==="/login"){
      return <Navigate to="/" replace />;
    }else{
      return <>{children}</>;
    }
  }else{
    if(isInwhitelist){
      return <>{children}</>;
    }else{
      return <Navigate to="login" replace />;
    }
  }

}
export default Guard