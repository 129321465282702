const store ={
    state:{
        num:20,
    },
    
    actions:{
        add(newstate,action){
             newstate.num=newstate.num+action.val;
             return newstate;
        },
    },
    actionnames:{
    },
}
let actionnames={}
for (let key in store.actions){
    actionnames[key]=key
}
store.actionnames=actionnames;
export default store