const store ={
    state:{
        user:{
            id:'',
            username:'',
            company:{
                name:'',
            }
        },
        menu:[],
        permissions:[],

    },
    
    actions:{
        user_login(newstate,action){
             //newstate={ ...newstate, user: newstate }
             newstate.user=action.val;

        },
        user_logout(newstate,action){
            newstate={ ...newstate, user: null }
        },
        setmenu(newstate,action){
            newstate.menu=action.val;
        },
        setpermissions(newstate,action){
            newstate.permissions=action.val;
        },

   
    },
    actionnames:{
    },
}
let actionnames={}
for (let key in store.actions){
    actionnames[key]=key
}
store.actionnames=actionnames;
export default store