import menuAction from "./index"

let reducer = (state= {...menuAction.state,},action)=>{

  let newState = { ...state };
    for(let key in menuAction.actionnames){
        if(action.type=== menuAction.actionnames[key]){
            menuAction.actions[menuAction.actionnames[key]](newState,action)
        }
        break;
    }
    
return newState
}
export default reducer