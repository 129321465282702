import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'tdesign-react/es/style/index.css'; // 少量公共样式
import 'tdesign-react/esm/style/index.js'; // 少量公共样式
import {BrowserRouter} from 'react-router-dom'
import { Provider } from 'react-redux';
import store from '@@/store'
import Guard from "@@/router/guard"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>

        <BrowserRouter>
            <Guard>

            <App />
            </Guard>
               

        </BrowserRouter>
    </Provider>
    
);


