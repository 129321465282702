import {useRoutes} from 'react-router-dom'

import '@@/locales/i18n.js'
import router from "@@/router"



function App() {
  const outlet =useRoutes(router)


  return (
    
    outlet

    
  );
}

export default App;
