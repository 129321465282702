import { legacy_createStore,combineReducers } from 'redux';

import userAction from './userState/reducer';
import numAction from './numState/reducer';
import menuAction from './menuState/reducer';

const reducers=combineReducers({
    numAction,
    userAction,
    menuAction,
    //多个模块就在此处添加
})

//const store = legacy_createStore(reducers,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const store = legacy_createStore(reducers)
export default store;