
export default {
    "login": {
        "needcha": "Please enter the captcha!",
        "loginsuccess": "Login successful",
        "needpwd": "Required",
        "needcompany": "Required",
        "needusername": "Required",
        "leasttwo": "At least two characters are needed",
        "title": "Sign in to System",
        "companyholder": "Organization",
        "usernameholder": "Username",
        "pwdholder": "Password",
        "chaholder": "Captcha",
        "login": "Sign in"
    },
    "layout": {
        "title": "Backend",
        "usercenter": "User",
        "logout": "Logout",
        "logouted": "Logged out",
        "admin": "Admin",
        "tip": "Tip",
        "sure": "Are you sure to logout?",
        "copyright": "Copyright © 2024 flykoi. All Rights Reserved",
        "cancel": "Cancel",
        "confirm": "Confirm"
    },
    "common": {
        "getdatafalse": "Failed to fetch data",
        "suredel": "Are you sure to delete?",
        "del": "Delete",
        "edit": "Edit",
        "add": "Add",
        "success": "Succeeded",
        "failed": "Failed",
        "name": "Name",
        "required": "Required",
        "needword": "At least {{count}} characters are needed",
        "parent": "Parent",
        "rules": "Permissions",
        "order": "Order",
        "submit": "Submit",
        "orderdesc": "Order is from smallest to largest; smaller numbers display first. If the same, they will display in the order added.",
        "mobile": "Phone",
        "title": "Position",
        "department": "Department",
        "email": "Email",
        "starttime": "Start date",
        "username": "Username",
        "action": "Action",
        "cancel": "Cancel",
        "save": "Save"
    },
    "auth": {
        "action": "Action",
        "menu": "Menu",
        "catalog": "Catalog",
        "name": "Name",
        "type": "Type",
        "normal": "Normal",
        "freeze": "Frozen",
        "unfreeze": "Unfreeze",
        "mainaccount": "Main Account",
        "normalaccount": "Regular Account",
        "username": "Login Username",
        "role": "Role",
        "nickname": "Nickname",
        "lasttime": "Last Login Time",
        "lastip": "Last Login IP",
        "company": "Organization",
        "accounttype": "Account Type",
        "status": "Status",
        "resetpwd": "Reset Password",
        "accountcount": "Total Number of Accounts",
        "desc": "The login name must be unique. The default password is 123456. Users should change the default password upon logging in to prevent unauthorized access."
    },
    "account": {
        "userinfo": "User Information",
        "username": "Username",
        "nickname": "Nickname",
        "lasttime": "Last Login Time",
        "lastip": "Last Login IP",
        "timezone": "Time Zone",
        "company": "Organization",
        "changepwd": "Change Password",
        "oldpwd": "Old Password",
        "newpwd": "New Password",
        "repepwd": "Re-enter New Password",
        "input": "Please fill in all required data.",
        "diff": "The new passwords entered do not match."
    },
    "player": {
        "name": "Name",
        "birth": "Date of Birth",
        "nationalities": "Nationality",
        "nationalities2": "Second Nationality",
        "placeofbirth": "Place of Birth",
        "height": "Height",
        "weight": "Weight",
        "creatornickname": "Creator",
        "updaternickname": "Updater",
        "editbasicinfo": "Edit",
        "position": "Position",
        "attribute": "Attribute",
        "injury": "Injury",
        "injuryname": "Injury Name",
        "injurytime": "Injury Date",
        "level": "Severity",
        "major": "Major",
        "moderate": "Moderate",
        "minor": "Minor",
        "slight": "Slight",
        "reason": "Cause",
        "timeout": "Return Date",
        "basic": "Basic",
        "good": "Strengths",
        "bad": "Weaknesses",
        "summary": "Summary",
        "report": "Report",
        "trainplan": "Training Plan",
        "foot": "Preferred Foot",
        "left": "Left Foot",
        "right": "Right Foot",
        "bothfoot": "Both Feet",
        "level":"Level",
        "club": "Club",
        "print":"Print",
    },
    "component":{
        "position":{
            "gk":"GK",
            "sw":"SW",
            "dr":"DR",
            "dc":"DC",
            "dl":"DL",
            "dm":"DM",
            "wbl":"WBL",
            "wbr":"WBR",
            "ml":"ML",
            "mc":"MC",
            "mr":"MR",
            "aml":"AML",
            "amc":"AMC",
            "amr":"AMR",
            "st":"ST",
            
        }
    },
    "attributes":{
        "TechnicalAttributes":"Technical",  
        "Crossing":"Crossing",  
        "Dribbling":"Dribbling",  
        "Finishing":"Finishing",  
        "Heading":"Heading",  
        "LongShots":"LongShots",  
        "Marking":"Marking",  
        "Passing":"Passing",  
        "Tackling":"Tackling",  
        "FirstTouch":"FirstTouch",  
        "Technique":"Technique",  
        "Penalty":"Penalty",  
        "Corner":"Corner",  
        "SetPieces":"SetPieces",  
        "LongThrows":"LongThrows",  
        
        "GKAttributes":"GK Attributes",  
        "AerialAbility":"AerialAbility",  
        "CommandOfArea":"CommandOfArea",  
        "Communication":"Communication",  
        "Eccentricity":"Eccentricity",  
        "Handling":"Handling",  
        "Kicking":"Kicking",  
        "OneOnOnes":"OneOnOnes",  
        "Reflexes":"Reflexes",  
        "RushingOut":"RushingOut",  
        "TendencyToPunch":"TendencyToPunch",  
        "Throwing":"Throwing",  
        
        "MentalAttributes":"Mental",  
        "Aggression":"Aggression",  
        "Anticipation":"Anticipation",  
        "Bravery":"Bravery",  
        "Composure":"Composure",  
        "Concentration":"Concentration",  
        "Vision":"Vision",  
        "Decisions":"Decisions",  
        "Determination":"Determination",  
        "Flair":"Flair",  
        "Leadership":"Leadership",  
        "OffTheBall":"OffTheBall",  
        "Positioning":"Positioning",  
        "TeamWork":"TeamWork",  
        "WorkRate":"WorkRate",  
        
        "PhysicalAttributes":"Physical",  
        "Acceleration":"Acceleration",  
        "Agility":"Agility",  
        "Balance":"Balance",  
        "Jumping":"Jumping",  
        "NaturalFitness":"NaturalFitness",  
        "Pace":"Pace",  
        "Stamia":"Stamia",  
        "Strength":"Strength",  
        "InjuryProneness":"InjuryProneness",  
        
        "PersonalityAttributes":"Personality",  
        "Adaptability":"Adaptability",  
        "Ambition":"Ambition",  
        "Loyalty":"Loyalty",  
        "Pressure":"Pressure",  
        "Professional":"Professional",  
        "Sportsmanship":"Sportsmanship",  
        "Temperament":"Temperament",  
        "Controversy":"Controversy",  
        "Consistency":"Consistency",  
        "Dirtiness":"Dirtiness",  
        "ImpMatches":"ImpMatches",  
        "Versatility":"Versatility",
        "desc":"Range is from 0 to 99.",
    },
    "scout": {
        "people": "People",
        "thing": "Task",
        "level": "Priority",
        "high": "High",
        "middle": "Medium",
        "low": "Low"
    },
    "menu": {
        "auth": "Authorization",
        "auth-account": "Account",
        "auth-role": "Role",
        "auth-menu": "Menu",
        "auth-menu-list": "View Menu",
        "board": "Dashboard",
        "board-dashboard": "Dashboard",
        "account": "Account",
        "account-center": "User Profile",
        "account-setting": "Change Password",
        "account-center-view": "View User Profile",
        "account-setting-pwd": "Change Password",
        "board-dashboard-main": "Dashboard Data",
        "common": "Common",
        "common-infolist": "Directory",
        "common-orgstructure": "Organizational Structure",
        "common-orgstructure-list": "View",
        "common-orgstructure-add": "Add",
        "common-orgstructure-edit": "Edit",
        "common-orgstructure-delete": "Delete",
        "common-infolist-list": "Directory View",
        "common-infolist-add": "Add to Directory",
        "common-infolist-edit": "Edit Directory Entry",
        "common-infolist-delete": "Delete from Directory",
        "common-infolist-options": "Directory Options",
        "player": "Player",
        "scout": "Scouting",
        "medical": "Medical",
        "player-list": "Player List",
        "player-comparison": "Player Comparison",
        "scout-assignments": "Assignments",
        "scout-focus": "Focus",
        "scout-shortlist": "Shortlist",
        "medical-overview": "Injury Overview",
        "medical-record": "Injury Record",
        "player-list-add": "Add",
        "player-list-list": "List",
        "player-list-edit": "Edit",
        "player-list-position": "Position",
        "player-list-saveposition": "Save Position",
        "player-list-attributes": "Attributes",
        "player-list-saveattributes": "Save Attributes",
        "player-list-attributeschange": "Attributes Change",
        "player-list-injury": "Injury",
        "player-list-addinjury": "Add Injury",
        "player-list-editinjury": "Edit Injury",
        "player-list-delinjury": "Delete Injury",
        "player-list-report": "Report",
        "player-list-savereport": "Save Report",
        "player-list-train": "Training",
        "player-list-savetrain": "Save Training",
        "player-list-delete": "Delete",
        "player-comparison-list": "List",
        "player-comparison-info": "Information",
        "player-list-print": "Print",
        "medical-overview-overview": "Injury Overview",
        "medical-record-list": "Injury History",
        "scout-assignments-add": "Add",
        "scout-assignments-list": "View",
        "scout-assignments-edit": "Edit",
        "scout-assignments-delete": "Delete",
        "scout-focus-add": "Add",
        "scout-focus-list": "View",
        "scout-focus-edit": "Edit",
        "scout-focus-delete": "Delete",
        "scout-shortlist-add": "Add",
        "scout-shortlist-list": "List",
        "scout-shortlist-edit": "Edit",
        "scout-shortlist-position": "Position",
        "scout-shortlist-saveposition": "Save Position",
        "scout-shortlist-attribute": "Attributes",
        "scout-shortlist-saveattribute": "Save Attributes",
        "scout-shortlist-injury": "Injury",
        "scout-shortlist-addinjury": "Add Injury",
        "scout-shortlist-editinjury": "Edit Injury",
        "scout-shortlist-delinjury": "Delete Injury",
        "scout-shortlist-report": "Report",
        "scout-shortlist-savereport": "Save Report",
        "scout-shortlist-delete": "Delete",
        "train": "Train",
        "train-work": "Train",
        "train-work-add": "Add",
        "train-work-view": "View",
        "train-work-edit": "Edit",
        "train-work-delete": "Delete"
    },
    "board":{
        "player":"Players",
        "scout":"Scout Players",
        "injury":"Injuy Players",
      },
      "train":{
        "danger":"Red",
        "warning":"Yellow",
        "primary":"Blue",
        "success":"Green",
        "default":"Gray",
        "color":"Color",
        "thing":"Thing",
        "date":"Date",
      },
}