import i18n from 'i18next';  
import { initReactI18next } from 'react-i18next'; 

import en from '@@/locales/en/translation.js';  
import zh from '@@/locales/zh/translation.js'; 
import fr from '@@/locales/fr/translation.js'; 
import it from '@@/locales/it/translation.js'; 
import ja from '@@/locales/ja/translation.js'; 
import es from '@@/locales/es/translation.js'; 
import vi from '@@/locales/vi/translation.js'; 
import th from '@@/locales/th/translation.js'; 
import de from '@@/locales/de/translation.js'; 
import nl from '@@/locales/nl/translation.js'; 
import pt from '@@/locales/pt/translation.js'; 
i18n  
  .use(initReactI18next) // 初始化react-i18next  
  .init({  
    resources: {  
      en: {  
        translation: en,  
      },  
      zh: {  
        translation: zh,  
      },  
      fr: {  
        translation: fr,  
      },  
      it: {  
        translation: it,  
      },  
      ja: {  
        translation: ja,  
      },  
      es: {  
        translation: es,  
      },  
      vi: {  
        translation: vi,  
      },  
      th: {  
        translation: th,  
      },  
      de: {  
        translation: de,  
      },  
      nl: {  
        translation: nl,  
      },  
      pt: {  
        translation: pt,  
      },  
    },  
    lng: 'zh', // 默认语言  
    fallbackLng: 'en', // 备用语言  
 
    interpolation: {  
      escapeValue: false, // 允许在翻译文本中使用HTML  
    },  
  });  
 
export default i18n;