const store ={
    state:{
        menu:'',
    },
    
    actions:{
        changemenu(newstate,action){
             newstate.menu=action.val;
             return newstate;
        },
    },
    actionnames:{
    },
}
let actionnames={}
for (let key in store.actions){
    actionnames[key]=key
}
store.actionnames=actionnames;
export default store