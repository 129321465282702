import userAction from "./index"

let reducer = (state= {...userAction.state,},action)=>{

  let newState = { ...state };

  for(let key in userAction.actionnames){
    if(action.type=== userAction.actionnames[key]){
      userAction.actions[userAction.actionnames[key]](newState,action)
    }
  }
return newState
}
export default reducer